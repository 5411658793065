import React from 'react'
import { node, oneOf } from 'prop-types'
import classNames from 'classnames'
import styles from './SplitPanel.module.scss'

const SplitPanel = ({ children, reverse, vAlign, centerMobile }) => (
  <div
    className={classNames(
      styles.SplitPanel,
      reverse && styles.reverse,
      vAlign && styles[`vAlign-${vAlign}`],
      centerMobile && styles.centerMobile
    )}
  >
    <div className={styles.Left}>{children[0]}</div>
    <div className={styles.Right}>{children[1] && children[1]}</div>
  </div>
)

SplitPanel.propTypes = {
  children: node.isRequired,
  vAlign: oneOf(['top', 'middle', 'bottom'])
}

export default SplitPanel
