import { useEffect } from 'react'
import debounce from '@/lib/helpers/debounce'
import PropTypes from 'prop-types'

const LazyLoader = ({ children, threshold, loadMore, hasMore }) => {
  const handleScroll = debounce(() => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight
    const scrolled = winScroll / height

    if (scrolled > threshold) {
      if (hasMore) loadMore()
      else window.removeEventListener('scroll', handleScroll)
    }
  }, 30)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return children
}

export default LazyLoader

LazyLoader.propTypes = {
  /**
   * % of the page to scroll to load more items (0.1 - 1)
   */
  threshold: PropTypes.number,
  loadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired
}

LazyLoader.defaultProps = {
  threshold: 0.3,
  hasMore: true
}
