import { array, number } from 'prop-types'
import MasonryLayout from '@/components/Primitive/MasonryLayout'
import React from 'react'

const Gallery = ({ children, gap }) => {
  return <MasonryLayout items={children} gap={gap || 25} />
}

Gallery.propTypes = {
  galleryItems: array,
  gap: number
}

export default Gallery
