import { bool, number, string, oneOf } from 'prop-types'
import classNames from 'classnames'
import React from 'react'

import VisuallyHidden from '../VisuallyHidden'

import styles from './Spinner.module.scss'

/**
 * Simple SVG spinner which can be used to indicate loading.

 * If this component appears in the DOM but is not visible, pass the
 * \`paused\` prop to remove the performance overhead of the multiple
 * animations.
 */
const Spinner = ({ a11yText, paused, revealDelay, size, style, className }) => (
  <div
    className={classNames(
      styles.Spinner,
      paused && styles.paused,
      style && styles[style],
      className
    )}
    role="alert"
    aria-live="assertive"
    style={{
      ...(revealDelay && { animationDelay: `${revealDelay}ms` }),
      ...(size && { fontSize: size })
    }}
  >
    <div className={classNames(styles.SpinnerInner)}>
      <VisuallyHidden>{a11yText}</VisuallyHidden>
      <div className={classNames(styles.Animation)}>
        <div className={styles.Loader} id="spinner" />
      </div>
    </div>
  </div>
)

Spinner.defaultProps = {
  a11yText: 'Loading…',
  size: '1em'
}

Spinner.propTypes = {
  a11yText: string,
  paused: bool,
  revealDelay: number,
  size: string,
  style: oneOf(['primary', 'dark', 'secondary'])
}

export default Spinner
