import Gallery from '@/components/Common/Gallery'
import React from 'react'

import createMedia from './createMedia'

export function createGallery(component) {
  const items = component.items
  if (!items) return <> </>

  const styles = {
    marginBottom: '10px'
  }

  const galleryItems = items.map((item) => {
    return createMedia(item)
  })

  return (
    galleryItems && (
      <div style={styles}>
        <Gallery gap={1}>{galleryItems}</Gallery>
      </div>
    )
  )
}

export default createGallery
