import { useState } from 'react'

// A hook to load items. Should be used with LazyLoader component.
const useItemLoader = (items, initialAmountToLoad = 10, loadAmount = 6) => {
  const [loaded, setLoaded] = useState(initialAmountToLoad)
  const [hasMore, setHasMore] = useState(true)
  const loadMore = () => {
    const itemsLeft = items.length - loaded
    const totalAmount = loaded + loadAmount

    if (itemsLeft > 0) {
      setLoaded(totalAmount)
      setHasMore(true)
    } else {
      setHasMore(false)
    }
  }

  const displayedItems = []
  if (items?.length > 0) {
    for (let i = 0; i < loaded; i++) {
      items[i] && displayedItems.push(items[i])
    }
  }

  return [displayedItems, hasMore, loadMore]
}

export default useItemLoader
