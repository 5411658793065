import { client } from '@/lib/sanity'
import { NextSeo } from 'next-seo'
import { string, object } from 'prop-types'
import { useNextSanityImage } from 'next-sanity-image'

import config from '../../../../config'

const { url } = config.meta

const StandardMeta = ({ title, description, canonical, image }) => {
  const canonicalUrl = `${url}${canonical}`
  const ogImage = useNextSanityImage(client, image)
  const config = {
    title: title,
    description: description,
    ...(canonical && { canonical: canonicalUrl }),
    openGraph: {
      title: title,
      description: description,
      url: canonicalUrl,
      images: ogImage && [
        {
          url: ogImage.src,
          width: ogImage.width,
          height: ogImage.height,
          alt: 'In Real Life'
        }
      ]
    }
  }
  return <NextSeo {...config} />
}

StandardMeta.propTypes = {
  title: string,
  description: string,
  canonical: string,
  image: object
}

export default StandardMeta
