import React from 'react'
import createMedia from './createMedia'
import SplitPanel from '@/components/Common/SplitPanel'

export function createSplitPanel(component) {
  const content = component.content

  if (!content) return <> </>

  // Build content
  const splitPanelComponents = content.map((item) => {
    return createMedia(item)
  })

  const props = {
    ...(component.vAlign && { vAlign: component.vAlign }),
    ...(component.centerMobile && { centerMobile: component.centerMobile })
  }

  return (
    splitPanelComponents && (
      <SplitPanel {...props} reverse={component.reverse} key={component._key}>
        {splitPanelComponents}
      </SplitPanel>
    )
  )
}
export default createSplitPanel
