import { bool, node, string, oneOf } from 'prop-types'
import ButtonBase from '@/components/Primitive/ButtonBase'
import classNames from 'classnames'
import React from 'react'
import Spinner from '@/components/Primitive/Spinner'

import styles from './ButtonStandard.module.scss'

/**
 * Basic button component, building on the ButtonBase component.
 */
const ButtonStandard = ({
  children,
  className,
  disabled,
  loading,
  size = 'normal',
  secondary,
  minimal,
  ...other
}) => (
  <ButtonBase
    className={classNames(
      styles.ButtonStandard,
      disabled && styles.disabled,
      loading && styles.loading,
      size && styles[size],
      secondary && styles.secondary,
      minimal && styles.minimal,
      className && className
    )}
    disabled={disabled}
    {...other}
  >
    <span className={styles.ButtonStandardContent}>{children}</span>
    {loading && (
      <span className={styles.ButtonStandardSpinner}>
        <Spinner
          style={!secondary && 'secondary'} // Inverse loading spinner
          size={iconSizeMap[size]}
          color="white"
          revealDelay={200}
        />
      </span>
    )}
  </ButtonBase>
)

const iconSizeMap = {
  small: '1em',
  normal: '1.1em',
  mediumSmall: '1.2em',
  large: '1.5em'
}

ButtonStandard.propTypes = {
  children: node,
  className: string,
  disabled: bool,
  loading: bool,
  minimal: bool,
  size: oneOf(['small', 'normal', 'mediumSmall', 'medium', 'large'])
}

export default React.memo(ButtonStandard)
