import { array, number, shape } from 'prop-types'
import LazyLoader from '@/lib/helpers/lazy-loader/lazyLoader'
import MasonryLayout from '@/lib/helpers/masonry/masonry-layout'
import React from 'react'
import useItemLoader from '@/hooks/useItemLoader'

/**
 * Basic masnory layout with lazy loading.
 */
const Masonry = ({
  items,
  gap,
  widths,
  threshold,
  loadAmount = 10,
  initialAmountToLoad = 10
}) => {
  const [displayedItems, hasMore, loadMore] = useItemLoader(
    items,
    initialAmountToLoad,
    loadAmount
  )

  return (
    <LazyLoader threshold={threshold} loadMore={loadMore} hasMore={hasMore}>
      <MasonryLayout gap={gap} widths={widths}>
        {displayedItems}
      </MasonryLayout>
    </LazyLoader>
  )
}

Masonry.propTypes = {
  items: array.isRequired,
  /**
   * Column margin in px
   */
  gap: number,
  /**
   * Number of items to load after threshold is reached
   */
  loadAmount: number,
  /** Number of items to load initially */
  initialAmountToLoad: number,
  /**
   * % of the page to scroll to load more items (0.1 - 1)
   */
  threshold: number,
  /**
   * Items per row for different screen sizes
   */
  widths: shape({
    desktop: number,
    tablet: number,
    mobile: number
  })
}

export default Masonry
